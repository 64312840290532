<template>
  <AppModal :id="modal.id" :title="modal.label" :size="'xl'">
    <template v-slot:contents>
      <div class="pc-user-privacy-policy-item">
        株式会社ティーピーズ（以下「当社」といいます。）は、お客様の個人情報保護の重要性について認識し、個人情報の保護に関する法律（以下「個人情報保護法」といいます。）を遵守すると共に、以下のプライバシーポリシー（以下「本プライバシーポリシー」といいます。）に従い、適切な取扱い及び保護に努めます。
      </div>

      <div
        v-for="(item, index) in userPrivacyPolicy"
        :key="`${item.page}-pc-user-privacy-policy-${index}`"
      >
        <div
          v-for="(content, index) in item.contents"
          :key="`${item.page}-pc-user-privacy-policy-item-wrapper-${index}`"
        >
          <div class="pc-user-privacy-policy-item-title-sub">{{ content.title }}</div>
          <div class="pc-user-privacy-policy-item">
            <p
              style="marginBottom: 0;"
              v-for="(line, index) in content.text"
              :key="`${item.page}-pc-user-privacy-policy-item-${index}`"
              class="line"
            >
              {{ line || '&nbsp;' }}
            </p>
          </div>
        </div>
      </div>

      <div>
        <div class="pc-user-privacy-policy-item mt-5 mb-5">
          {{ fixDateText }}
        </div>
      </div>
    </template>
  </AppModal>
</template>
  
  <script>
  import AppModal from '@/components/molecules/user/AppPcUserModal'
  import { USER_PRIVACY_POLICY, USER_PRIVACY_POLICY_FIXED_DATE  } from '@/constants/user'
  import { OPTIONAL_MENU } from '@/constants/admin'
  
  export default {
    name: 'AppPcUserPrivacyPolicyModal',
    components: {
      AppModal
    },
    data() {
      return {
        modal: OPTIONAL_MENU.find(item => item.id === 'privacy-policy'),
        userPrivacyPolicy: USER_PRIVACY_POLICY,
        fixDateText: USER_PRIVACY_POLICY_FIXED_DATE,
      }
    },
  }
  </script>
  
  <style lang="scss" scoped>
  .pc-user-privacy-policy-item {
    font-size: 14px;
    color: #707070;
    .line { line-height: 1.5rem; }
  }
  .pc-user-privacy-policy-item-title-sub {
    font-size: 16px;
    font-weight: bold;
    margin: 42px 0 24px 0;
    color: #707070;
  }
  </style>
  