<template>
  <div class="map-area">
    <MapContents
      v-bind="mapConfig"
      v-bind:places="displayPlaces"
      v-bind:showUiLayer="true"
      v-bind:enabledCluster="true"
      v-bind:waitingCurrentLocation="waitingCurrentLocation"
      v-on:map-loadend="$_onMapLoadEnd"
    >
      <!-- Top UI -->
      <template v-slot:topUi>
        <CurrentLocationLabel
          v-bind:show="showCurrentLocationLabel"
          v-bind:waitingCurrentLocation="waitingCurrentLocation"
          v-on:hide="() => { showCurrentLocationLabel = false }"
        />
        <input
          class="keyword-input"
          type="text"
          placeholder="スペース名、地名など"
          v-model="keyword"
          @input="$_setSearchKeyword"
        />
      </template>

      <!-- Middle UI -->
      <template v-slot:middleUi>
        <CurrentLocationButton
          :waitingCurrentLocation="waitingCurrentLocation"
          @click-button="$_onClickCurrentLocationButton"
        />
      </template>
    </MapContents>

    <!-- Place detail drawer -->
    <div class="side-drawer" :class="{ 'side-drawer-open': isSideDrawerOpen }">
      <SideSlideContents
        :isSideDrawerOpen="isSideDrawerOpen"
        @close-button-click="$_hideDrawer"
      />
    </div>
  </div>
</template>

<script>
/**
 * 責務： MapContentsに表示対象のプレイスを渡す、Slot要素間の調整、SideSliderの開閉
 */
import MapContents from '@/components/molecules/user/AppUserMapContents'
import CurrentLocationButton from '@/components/molecules/user/AppUserCurrentLocationButton'
import CurrentLocationLabel from '@/components/molecules/user/AppUserCurrentLocationLabel'
import SideSlideContents from '@/components/organisms/user/pc/AppUserPcSideSlideContents'
import { distanceSort } from '@/helper/user'
import { getFilterCallBacks } from '@/helper/place'
import { showInfoPopupAlert, showBottomInfoToast } from '@/helper/common'
import { DEFAULT_POSITION, DEFAULT_ZOOM } from '@/constants/user'
import { mapGetters } from 'vuex'

export default {
  name: 'AppPcUserMap',
  components: {
    MapContents,
    CurrentLocationButton,
    CurrentLocationLabel,
    SideSlideContents
  },
  data() {
    return {
      map: null,
      google: null,
      conditionalPlaces: [],
      watchPositionId: null,
      waitingCurrentLocation: false,
      keyword: '',
      isSideDrawerOpen: false,
      showCurrentLocationLabel: false
    }
  },
  computed: {
    ...mapGetters(
      'map',
      [
        'mapConfig',
        'mapCenter',
        'mapZoom',
        'mapBounds',
        'currentLocation',
        'selectedMarker',
        'basePoint'
      ]
    ),
    ...mapGetters(
      'place',
      [
       
        'validSpaces',
        'validEvents',
      ]
    ),
    ...mapGetters(
      'filter',
      [
        'hasCondition',
        'conditions',
        'prevConditions'
      ]
    ),
    targetTypePlaces() {
      if (this.conditions.showSpace && !this.conditions.showEvent) {
        // only space
        return [...this.validSpaces]
      } else if (this.conditions.showEvent && !this.conditions.showSpace) {
        // only event
        return [...this.validEvents]
      } else if (this.conditions.showSpace && this.conditions.showEvent) {
        // both
        return [
          ...this.validSpaces,
          ...this.validEvents
        ]
      } else {
        // none
        return []
      }
    },
    isTypeChanged() {
      return (
        this.conditions.showSpace !== this.prevConditions.showSpace ||
        this.conditions.showEvent !== this.prevConditions.showEvent
      )
    },
    displayPlaces() {
      return this.hasCondition ? [...this.conditionalPlaces] : [...this.targetTypePlaces]
    },
  },
  watch: {
    conditions: {
      handler() {
        this.$_filterPlaceByConditions()
      },
      deep: true
    },
    selectedMarker() {
      if (this.selectedMarker && this.selectedMarker.placeId) {
        this.$store.dispatch('place/setSelectedPlace', this.selectedMarker.placeId)
        // 右drawerが閉じていたら開く
        if (!this.isSideDrawerOpen) this.$_showDrawer()
      }
    },
    currentLocation() {
      this.showCurrentLocationLabel = !this.currentLocation
    }
  },
  async created() {
    this.$store.dispatch('loadingMask/showLoadingMask')

    try {
      // フィルタリング条件を初期化
      this.$store.dispatch('filter/resetState')

      // 距離ソートの基準点取得
      if (
        !this.basePoint ||
        (!this.basePoint.lat || !this.basePoint.lng) ||
        (
          !Math.floor(this.basePoint.lat) ||
          !Math.floor(this.basePoint.lng)
        ) // lat: 0.000..., lng: 0.000...があり得る
      ) this.$_setBasePoint()

      // Load chunk data (do not wait)
      await this.$store.dispatch('place/setChunks')
    } catch (e) {
      alert(e)
    } finally {
      this.$store.dispatch('loadingMask/hideLoadingMask')
    }
  },
  async mounted() {
    this.showCurrentLocationLabel = !this.currentLocation

    // 運営会社、利用規約、プライバシーポリシー表示
    await this.$nextTick()
    if (this.$route.query.openModalId) {
      this.$bvModal.show(this.$route.query.openModalId)
    } 
  },
  methods: {
    /**
     * 
     */
    $_setBasePoint() {
      // Has current location ?
      if (this.currentLocation) {
        this.$store.dispatch('map/setBasePoint', this.currentLocation)
      } else {
        // IPから取得はメリットとコストが釣り合わないので廃止
        this.$store.dispatch('map/setBasePoint', DEFAULT_POSITION)
      }
    },
    /**
     * 
     */
    $_setSearchKeyword() {
      // キーワードなしの場合は '' を設定（constants/userの初期値参照）
      this.$store.dispatch('filter/setConditions', { keyword: this.keyword || '' })
    },
    /**
     * 
     */
    $_showDrawer() {
      this.isSideDrawerOpen = true
    },
    /**
     * 
     */
    $_hideDrawer() {
      this.isSideDrawerOpen = false
    },
    /**
     * 
     */
    $_onClickCurrentLocationButton() {
      if (this.map && this.currentLocation) {
        // 現在位置へ移動
        this.map.setCenter({
          lat: this.currentLocation.lat,
          lng: this.currentLocation.lng,
        })
      } else {
        // 現在位置を要求
        this.$_watchCurrentLocation(true)
      }
    },
    /**
     * 
     * @param {*} panTo 
     */
    $_watchCurrentLocation: async function (panTo=false) {
      this.waitingCurrentLocation = true

      if (!navigator || !navigator.geolocation) {
        // Geolocation API非対応
        alert("この端末では位置情報が取得できません")
        this.waitingCurrentLocation = false
        return
      }

      // 以前のWatchIDをクリア（意味があるかは不明）
      if (this.watchPositionId) {
        navigator.geolocation.clearWatch(this.watchPositionId)
      }

      // Geolocation API対応
      this.watchPositionId = navigator.geolocation.watchPosition(
        (position) => this.$_setCurrentLocation(position, panTo), // success
        (error) => this.$_showErrorCurrentLocation(error),        // failed
        {
          enableHighAccuracy: false , // そんなに正確じゃなくていい
          maximumAge: 30000 , // そんなに頻繁に更新しなくていいので30秒
          "timeout": 30000 ,
        }
      )
    },
    /**
     * 
     * @param {*} position 
     */
    $_setCurrentLocation: async function (position) {
      try {
        const location = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        }

        // 初回取得時のみ自動的にパン
        if (!this.currentLocation) this.map.setCenter(location)

        this.$store.dispatch('map/setBasePoint', location)
        this.$store.dispatch('map/setCurrentLocation', location)
      } catch (error) {
        this.$store.dispatch('map/setCurrentLocation', null)
        alert(`現在位置の更新に失敗しました。${error}`)
      }
      this.waitingCurrentLocation = false
    },
    /**
     * 
     * @param {*} error 
     */
    $_showErrorCurrentLocation: function (error) {
      // エラーメッセージを表示
      switch(error.code) {
        case 1: { // PERMISSION_DENIED
          this.$store.dispatch('modal/setModalId', 'failed-get-location')
          break
        }
        case 2: // POSITION_UNAVAILABLE
          alert("現在位置が取得できませんでした")
          break
        case 3: // TIMEOUT
          alert("タイムアウトになりました")
          break
        default:
          console.log("その他のエラー(エラーコード:"+error.code+")")
          break
      }
      this.$store.dispatch('map/setCurrentLocation', null)
      this.waitingCurrentLocation = false
      this.$store.dispatch('loadingMask/hideLoadingMask')
    },
    /**
     * 
     * @param {*} param0 
     */
    $_onMapLoadEnd: function ({ map, google }) {
      this.map = map
      this.google = google

      // 少し待ってからマップを移動させないとピンが表示されない？
      setTimeout(() => {
        try {
          // Fitting bounds
          this.map.setZoom(this.mapZoom || DEFAULT_ZOOM)
          const center = this.mapCenter || this.basePoint || DEFAULT_POSITION
          this.map.panTo({
            lat: center.lat,
            lng: center.lng,
          })
          // query string による表示プレイスタイプ指定を反映
          if (this.$route.query.placeType) {
            this.$store.dispatch(
              'filter/setConditions',
              {
                  showSpace: this.$route.query.placeType.includes('space'),
                  showEvent: this.$route.query.placeType.includes('event')
              }
            )
          } 
        } catch(error) {
          console.log(error)
        } finally {
          this.$store.dispatch('loadingMask/hideLoadingMask')
        }
      }, 3000)
    },
    /**
     * 
     * @param {*} places 
     */
    $_fitBounds(places) {
      if (!this.map || !this.google) return

      const bounds = new this.google.maps.LatLngBounds()
      for (let i = 0; i < places.length; i++) {
        const coords = places[i].coords || places[i].coordinates
        bounds.extend(new this.google.maps.LatLng(coords.lat, coords.lng))
      }
      this.map.fitBounds(bounds)
    },
    /**
     * 
     */
    $_filterPlaceByConditions: function () {
      try {
        let newPlaces
        // 各プレイス毎にタイプ判定するのは冗長なので対象タイプのみフィルタにかける
        if (!this.targetTypePlaces.length) {
          // 対象タイププレイスなし -> プレイスなし
          newPlaces = []
        } else {
          const callbacks = getFilterCallBacks(this.conditions)
          newPlaces = [...this.targetTypePlaces]
          for (let i = 0; i < callbacks.length; i++) {
            newPlaces = newPlaces.filter(callbacks[i])
          }
        }

        if (!newPlaces.length) {
          showBottomInfoToast(
            'error',
            '条件に一致する情報が見つかりませんでした',
            3000,
            400
          )
        } else if (!this.isTypeChanged){ // タイプ切り替え時は bounds 移動しない
          // fit bounds to conditional places
          this.$_fitBounds(newPlaces)
        }

        // 距離順にソートして設定
        this.conditionalPlaces = newPlaces.sort((a, b) => distanceSort(a, b))
      } catch (error) {
        showInfoPopupAlert(
          `スペース/イベントの読み込みに失敗しました。<br /><br />${error.message || ''}`,
        )
        return
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.map-area {
  // control map size
  width: 100%;
  height: 100%;
  box-shadow: 0 4px 6px -4px rgba(0,0,0,0.6) inset;
  position: relative;
  .side-drawer {
    width: 0px;
    height: 100%;
    background-color: white;
    position: absolute;
    top: 0;
    right: 0;
    transition: width 0.5s ease;
  }
  .side-drawer-open {
    // width: 35%;
    width: 400px;
    min-width: 300px;
  }
  .keyword-input {
    width: 400px;
    margin: 12px;
    border-radius: 8px;
    border: solid 1px lightgray;
    padding: 12px 8px;
    font-size: 14px;
  }
}
</style>
