<template>
  <div class="app-user-pc-side-slide-contentsr">
    <b-card header-tag="header" footer-tag="footer">
      <!-- Header -->
      <template #header>
        <b-icon
          icon="x-circle"
          scale="2"
          @click="$emit('close-button-click')"
        />
      </template>

      <!-- Body -->
      <SpaceDetail v-if="isSpace" :isSideDrawerOpen="isSideDrawerOpen" />
      <EventDetail v-else-if="isEvent" :isSideDrawerOpen="isSideDrawerOpen" />

      <!-- Footer -->
      <template v-if="showAds" #footer>
        <!-- ADS -->
        <div class="ads-wrapper">
          <img src="@/assets/image/ad_sample.png" />
        </div>
      </template>
    </b-card>

    <LoadingMask
      v-if="isSideDrawerOpen"
      :partialId="'pc-side-slide-contents'"
    />
  </div>
</template>

<script>
import LoadingMask from '@/components/molecules/common/AppPartialLoadingMask'
import SpaceDetail from '@/components/organisms/user/pc/AppUserPcSpaceDetail.vue'
import EventDetail from '@/components/organisms/user/pc/AppUserPcEventDetail.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'AppUserPcSideSlideContents',
  components: {
    LoadingMask,
    SpaceDetail,
    EventDetail,
  },
  props: {
    isSideDrawerOpen: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters('place', { place: 'selectedPlace' }),
    isSpace: function () {
      return this.place && this.place.type === 'space'
    },
    isEvent: function () {
      return this.place && this.place.type === 'event'
    },
    showAds: function () {
      return !location.href.includes('https://poshspace.jp')
    },
  },
}
</script>

<style lang="scss" scoped>
.app-user-pc-side-slide-contentsr {
  width: 100%;
  height: 100%;
  .card {
    // コンテンツの body に対する overflow-y を効かせるため
    height: 100%;
    .card-header {
      svg {
        color: gray;
      }
    }
    // スクロールバー分調整
    .card-body {
      padding: 1.25rem 0.25rem 1.25rem 1.25rem;
    }
    .card-footer {
      padding: 0;
    }
  }
  .ads-wrapper {
    width: 100%;
    margin: 8px 0;
    text-align: center;
    img {
      width: 100%;
    }
  }
}
</style>