<template>
  <div class="app-pc-user-page-frame">
    <header v-show="showHeader">
      <appPcUserHeader />
    </header>

    <aside v-show="showAside">
      <appPcUserAside 
        @click-modal-menu-item="(id) => $emit('open-modal', id)"
      />
    </aside>

    <div :class="{ 'pc-user-wrapper': showHeader && showAside }">
      <slot name="content" />
    </div>
  </div>
</template>
  
<script>
import appPcUserHeader from '@/components/organisms/user/pc/AppPcUserHeader'
import appPcUserAside from '@/components/organisms/user/pc/AppPcUserAside'
  
export default {
  name: 'AppPcUserPageFrame',
  components: {
    appPcUserHeader,
    appPcUserAside,
  },
  data(){
    return{
      showHeader: false,
      showAside: false,
    }
  },
  watch: {
    $route: async function(to, from) {
      this.showHeader = this.$router.history.current.meta.showHeader
      this.showAside = this.$router.history.current.meta.showAside
    },
  },
  mounted(){
    this.showHeader = this.$router.history.current.meta.showHeader
    this.showAside = this.$router.history.current.meta.showAside
  },
  methods: {},
  computed: {}
}
</script>
<style lang="scss" scoped>
.app-pc-user-page-frame {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}
/* 横幅992px以下 */
.pc-user-wrapper {
  height: calc(100vh - 56px);
  margin-top: 56px;
}
/* 横幅992px以上 */
@media (min-width: 992px) {
  /* メニューボタン非表示 */
  #menubtn,
  .b-sidebar-backdrop {
    display: none;
  }

  /* Sidebar常時表示 */
  .b-sidebar {
    display: block !important;
  }

  /* Sidebarの幅を確保 */
  .pc-user-wrapper {
    margin-left: 250px;
    width: calc(100% - 250px);
    height: calc(100vh - 56px);
    margin-top: 56px;
  }
}

</style>
  