<template>
  <div v-if="placeDetail" class="app-user-event-detailr">
    <!-- Header -->
    <div  class="header-wrapper">
      <!-- PCユーザーのお気に入り操作は、ログイン機能実装後にしたい -->
      <!-- <div v-if="isSideDrawerOpen" class="like-button-wrapper">
        <LikeButton
          :liked="isLiked" 
          :isProcessing="isProcessing"
          :width="'46px'"
          :height="'46px'"
          @like-button-clicked="$_switchDisplayStyle"
        />
      </div> -->
      <div class="event-detail-chip-wrapper">
        <Chip
          :title="'イベント'"
          :textColor="'#fff'"
          :backgroundColor="'#95BB00'"
        />
      </div>
      <div class="title-detail-wrapper">
        <div class="event-name">{{ eventName }}</div>
        <div class="like-count-wrapper">
          <b-icon
            v-bind:icon="'heart-fill'"
            aria-hidden="true" dark
          />
          <span class="like-count-number">
            {{ likesCount }}
          </span>
        </div>
      </div>
    </div>

    <!-- Body -->
    <div class="body-wrapper">
      <b-card no-body>
        <!-- Images -->
        <div class="event-image-wrapper">
          <AppUserImageBox
            v-if="thumbnailUrl"
            :src="thumbnailUrl"
            :width="'100%'"
            :height="'250px'"
          />
          <img
            v-else
            class="event-default-image"
            :src="defaultImage"
          />
        </div>
        <!-- サムネイル引用元URL -->
        <div v-if="copyRightUrl" class="copyright-url-wrapper">
          引用：
          <a
            class="copyright-url-wrapper"
            target="_blank"
            rel="noopener" 
            :href="copyRightUrl"
          >
            {{ copyRightUrl }}
          </a>
        </div>
        <!-- スペース名 -->
        <div class="event-detail-item-wrapper">
          <div class="item-title">
            スペース名
          </div>
          <p class="event-detail-item-text">
            {{ spaceName }}
          </p>
        </div>
        <!-- 開催日 -->
        <div class="event-detail-item-wrapper">
          <div class="item-title">
            開催日
          </div>
          <p
            class="event-detail-item-text mb-0"
            v-for="(dates, index) in displayHeldDateArray"
            :key="`held-date-${index}`"
          >
            {{ dates }}
          </p>
        </div>
        <!-- 募集期間 -->
        <div
          v-if="displayTermBegin ||   displayTermEnd"
          class="event-detail-item-wrapper"
        >
          <div class="item-title">
            募集期間
          </div>
          <div v-if="displayTermBegin || displayTermEnd">
            <p v-if="displayTermBegin" class="event-detail-item-text">
              開始：{{ displayTermBegin }}
            </p>
            <p v-if="displayTermEnd" class="event-detail-item-text">
              終了：{{ displayTermEnd }}
            </p>
          </div>
        </div>
        <!-- 掲載終了日 -->
        <div class="event-detail-item-wrapper">
          <div class="item-title">
            掲載終了日
          </div>
          <p class="event-detail-item-text">
            {{ displayEndOfPublicationDate }}
          </p>
        </div>
        <!-- Map -->
        <div class="access-map-container">
          <div class="item-title">
            アクセス
          </div>
          <div class="access-map">
            <MapContents
              v-if="coordinates"
              :showUiLayer="false"
              :places="[placeDetail]"
              :mapCenter="coordinates"
              :mapZoom="16"
              :mapOptions="mapOptions"
              :panTo="panTo"
              @map-loadend="({ map }) => {
                map.setZoom(18)
                map.panTo(coordinates)
              }"
            />
          </div>
          <p class="open-streetview" @click="$_onClickStreetView">
            Googleマップを別ウィンドウで開く
          </p>
          <p class="pin-position-remarks">
            ※ピンの位置は住所情報をもとに取得されるため、正確な位置からずれて表示される場合や、実際の出店場所とは異なる場合がありますのでご注意ください。
          </p>
        </div>
        <!-- Address -->
        <div class="address-container">
          <div class="item-title">
            住所
          </div>
          <div class="address-detail">
            <p class="zip-code">〒{{ address.zip }}</p>
            <p class="event-detail-item-text">
              {{ address.prefecture }}{{ address.area }} {{ address.address }}
            </p>
          </div>
        </div>
        <!-- Apply Url -->
        <div class="event-detail-item-wrapper">
          <div class="item-title">
            イベント情報掲載ページ
          </div>
          <div class="apply-url-detail">
            <a
              class="apply-urls"
              target="_blank"
              rel="noopener noreferrer"
              :href="eventInfoUrl"
              :style="{ fontSize: '12px' }"
            >
              {{ eventInfoUrl }}
            </a>
          </div>
        </div>
        <!-- note -->
        <p class="event-detail-note">
          ※記載されている内容は調査時の情報ですので、最新の情報<br />
          とは異なる場合がございます。正式な情報は公式の情報を<br />
          ご確認ください。
        </p>
      </b-card>
    </div>
  </div>
</template>

<script>
import MapContents from '@/components/molecules/user/AppUserMapContents'
// import LikeButton from '@/components/molecules/user/AppUserLikeButton'
import AppUserImageBox from '@/components/molecules/user/AppUserImageBox'
import Chip from '@/components/molecules/user/AppUserChip.vue'
import { getEvent } from '@/helper/firestore/event'
import { getSummaryEventTerm, showBottomInfoToast } from '@/helper/common'
import { updateLikes } from '@/helper/user'
import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
  name: 'AppUserPcEventDetail',
  components: {
    MapContents,
    // LikeButton,
    AppUserImageBox,
    Chip,
  },
  props: {
    isSideDrawerOpen: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      placeDetail: null,
      isProcessing: false,
      panTo: null,
      mapOptions: {
        disableDefaultUI: false,
        mapTypeControl: false,
        fullscreenControl: false,
        clickableIcons: false,
        keyboardShortcuts: false,
        zoomControl: true,
        streetViewControl: false,
        gestureHandling: 'cooperative'
      }
    }
  },
  watch: {
    async selectedPlace() {
      await this.$_setEvent()
      // 新しいプレイス位置へ地図をパン
      this.panTo = this.coordinates
    }
  },
  mounted: async function() {
    await this.$_setEvent()
  },
  computed: {
    ...mapGetters(
      'map',
      [ 'mapCenter', 'mapZoom' ]
    ),
    selectedPlace() { // Chunkデータ
      return this.$store.getters['place/selectedPlace']
    },
    selectedPlaceId() {
      return this.$store.getters['place/selectedPlaceId']
    },
    heldDates: function () {
      return (this.placeDetail && this.placeDetail.heldDates)
        ? this.placeDetail.heldDates
        : ''
    },
    copyRightUrl: function () {
      return (this.placeDetail && this.placeDetail.copyRightUrl)
        ? this.placeDetail.copyRightUrl
        : ''
    },
    eventInfoUrl: function () {
      return (this.placeDetail && this.placeDetail.eventInfoUrl)
        ? this.placeDetail.eventInfoUrl
        : ''
    },
    displayHeldDateArray: function () {
      const data = this.$_parseCsvData(this.heldDates)
      return data.length && data[0]
        ? getSummaryEventTerm(data)
        : ''
    },
    termBeginDate: function () {
      return (this.placeDetail && this.placeDetail.termBeginDate)
        ? this.placeDetail.termBeginDate
        : ''
    },
    displayTermBegin: function () {
      const data = this.$_parseCsvData(this.placeDetail.termBeginDate)
      return data.length && data[0]
        ? moment(data[0]).format('YYYY年MM月DD日(ddd)')
        : ''
    },
    termEndDate: function () {
      return (this.placeDetail && this.placeDetail.termEndDate)
        ? this.placeDetail.termEndDate
        : ''
    },
    displayTermEnd: function () {
      const data = this.$_parseCsvData(this.placeDetail.termEndDate)
      return data.length && data[0]
        ? moment(data[0]).format('YYYY年MM月DD日(ddd)')
        : ''
    },
    endOfPublicationDate: function () {
      return (this.placeDetail && this.placeDetail.endOfPublicationDate)
        ? this.placeDetail.endOfPublicationDate
        : ''
    },
    displayEndOfPublicationDate: function () {
      const data = this.$_parseCsvData(this.placeDetail.endOfPublicationDate)
      return Array.isArray(data) && data.length
        ? moment(data[0]).format('M月D日(ddd)')
        : ''
    },
    eventName: function () {
      return this.placeDetail.eventName
    },
    likesCount: function () {
      return (this.placeDetail && this.placeDetail.likes)
        ? Number(this.placeDetail.likes)
        : 0
    },
    bookmarkedPlaceIds: function () {
      return this.$store.getters.bookmarkedPlaceIds
    },
    isLiked: function () {
      return this.bookmarkedPlaceIds.includes(this.selectedPlaceId)
    },
    spaceName: function () {
      return this.placeDetail.spaceName
    },
    thumbnailUrl: function () {
      return this.placeDetail && this.placeDetail.exteriorImage
        ? this.placeDetail.exteriorImage
        : ''
    },
    title: function () {
      return this.placeDetail.eventName
        ? `${this.placeDetail.facilityName}/${this.placeDetail.eventName}`
        : this.placeDetail.facilityName
    },
    address: function() {
      return {
        zip: this.placeDetail.zip,
        prefecture: this.placeDetail.prefecture,
        area: this.placeDetail.area,
        address: this.placeDetail.address
      }
    },
    coordinates: function () {
      return this.placeDetail && this.placeDetail.coordinates
    },
    defaultImage: function () {
      return require('@/assets/image/place_default.png')
    }
  },
  methods: {
    /**
     * お気に入りボタンクリック処理
     */
    $_switchDisplayStyle: async function () {
      try {
        this.isProcessing = true

        // リモート、ストアのデータを更新
        await updateLikes(this.selectedPlace)
        // リモートデータ再取得
        this.placeDetail = await getEvent(this.selectedPlaceId)

        // 現状PC版に個人のお気に入りを見る機能はないので表示不要
        // showBottomInfoToast('info', this.toastText, 1500)
      } catch (error) {
        showBottomInfoToast('error', `いいね!更新処理に失敗しました ${error}`, 150000)
      } finally {
        this.isProcessing = false
      }
    },
    /**
     * イベント情報を取得して設定
     */
     async $_setEvent() {
      this.$_showLoadingMask()
      try {
        this.placeDetail = await getEvent(this.selectedPlaceId)
        await this.$nextTick()
      } catch (error) {
        alert(error)
      } finally {
        this.$_hideLoadingMask()
      }
    },
    /**
     * ストリートビューへのリンクへ遷移
     */
    $_onClickStreetView: function () {
      const coords = `
        ${this.placeDetail.coordinates.lat},
        ${this.placeDetail.coordinates.lng}
      `
      window.open(
        `https://www.google.com/maps/search/?api=1&query=${coords}`,
        '_blank',
        'noreferrer'
      )
    },
    /**
     * 
     * @param {*} data 
     */
    $_parseCsvData: function (data) {
      // 2種類のダブルクォーテーションと改行が交じることへの対処(CSVデータがソースのため)
      return data 
        ? data.replace(/”/g, '').replace(/"/g, '').replace(/\n/g, '').split(",")
        : []
    },
    $_showLoadingMask() {
      this.$store.dispatch(
        'loadingMask/setPartialId',
        'pc-side-slide-contents'
      )
    },
    $_hideLoadingMask() {
      this.$store.dispatch('loadingMask/setPartialId', null)
    },
  }
}
</script>

<style lang="scss" scoped>
.app-user-event-detailr {
  width: 100%;
  height: 100%;
  .header-wrapper {
    height: 96px;
    position: relative;
    box-shadow: none;
    .like-button-wrapper { 
      position: absolute;
      top: 0px;
      right: 20px;
      z-index: 9999;
      cursor: pointer;
    }
    .event-detail-chip-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #707070;
      position: sticky;
      background-color: #fff;
      top: 0;
      z-index: 999;
    }
    .title-detail-wrapper {
      position: sticky;
      top: 20px;
      z-index: 999;
      background-color: #fff;
      .event-name {
        width: calc(100% - 46px - 20px); // like button width and position.right
        color: #707070;
        padding: 8px 0;
      }
      .like-count-wrapper {
        color: #12B6D4;
        display: flex;
        align-items: center;
        font-weight: bold;
        margin-bottom: 8px;
        .like-count-number {
          margin: 0 0 0 8px;
        }
      }
    }
  }
  .body-wrapper {
    height: calc(100% - 96px);
    overflow-y: auto;
    /* overwrite */
    .card {
      border: none;
    }
    .item-title {
      font-weight: bold;
      margin: 2rem 0 1.25rem 0;
    }
    .event-image-wrapper {
      width: 100%;
      max-height: 300px;
      .event-default-image {
        width: 100%;
      }
    }
    .copyright-url-wrapper {
      color: #707070;
      font-size: 12px;
      margin-top: 16px;
      .copyright-url-wrapper {
        color: #707070;
        font-size: 12px;
      }
    }
    .event-detail-item-wrapper {
      color: #707070;  
    }
    .apply-urls {
      color: #707070 !important;  
      font-size: 16px;
      text-decoration: underline;
    }
    .event-detail-item-text {
      font-size: 14px;
      margin-bottom: 0;
    }
    .access-map-container {
      color: #707070;  
      .access-map {
        height: 260px;
      }
      .open-streetview {
        text-decoration: underline;
        font-size: 12px;
        line-height: 36px;
      }
      .pin-position-remarks {
        color: #707070;
        font-size: 12px;
        margin-bottom: 0;
      }
    }
    .address-container {
      color: #707070;  
    }
    .zip-code {
      font-size: 13px;
    }
    .event-detail-note {
      color: #707070;
      font-size: 12px;
    }
  }
}
</style>