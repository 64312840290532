<template>
  <b-sidebar
    id="sidebar-backdrop"
    backdrop
    shadow
    no-header
    body-class="custom-sidebar"
    width="250px"
  >
    <b-list-group flush>
      <div style="maxHeight: 75%">
        <b-list-group-item class="custom-sidebar-logo">
          <!-- Safari でSVGのグラデーションが効かないためpngで対応 -->
          <img class="posh-logo" :src="require('@/assets/image/logo/logo.png')" />
        </b-list-group-item>

        <PlaceTypeSwitches />

        <b-button
          v-b-toggle.collapse-2
          class="mb-2 title condition-button"
          :variant="isConditionOpen ? 'info' : 'outline-info'"
          @click="() => isConditionOpen = !isConditionOpen"
        >
          検索条件
          <b-icon :icon="isConditionOpen ? 'caret-up' : 'caret-down'"></b-icon>
        </b-button>
        <b-collapse v-if="!noTypeSelected" id="collapse-2" class="filter-condition-collapse">
          <div class="filter-conditions">
            <!-- 都道府県 -->
            <b-form-group
              class="item-title"
              label="都道府県(選択)"
              label-for="prefecture-selector"
            >
              <b-form-select
                id="prefecture-selector"
                v-model="selectedPrefecture"
                :options="prefectureOptions"
                :style="{ marginBottom: '0rem' }"
                size="sm"
              ></b-form-select>
            </b-form-group>

            <!-- 現在位置からの距離（位置情報が利用できる場合のみ表示） -->
            <b-form-group
              v-if="currentLocation"
              class="item-title"
              label="現在位置からの距離(以内)"
              label-for="distance-selector"
            >
              <b-form-select
                id="distance-selector"
                v-model="selectedDistance"
                :options="distances"
                :style="{ marginBottom: '1rem' }"
                size="sm"
              ></b-form-select>
            </b-form-group>

            <!-- お気に入り数 -->
            <div :style="{ marginBottom: '1rem' }">
              <label
                class="item-title"
                for="inline-form-select-likes-count"
              >
                お気に入り登録
              </label>
              <br />
              <b-form-spinbutton
                id="inline-form-select-likes-count"
                class="number-selector"
                v-model="likesCount"
                min="0"
                inline
              ></b-form-spinbutton>
              <p class="item-text" :style="{ display: 'inline-block' }">&nbsp;&nbsp;件以上</p>
            </div>

            <!-- クチコミ数（スペースのみ選択時に表示） -->
            <!--  Detailにクチコミ機能を組み込む必要が出てくるので次の段階でやる -->
            <!-- <div v-if="onlySpace" :style="{ marginBottom: '1rem' }">
              <label
                class="item-title"
                for="inline-form-select-likes-count"
              >
                クチコミ
              </label>
              <br />
              <b-form-spinbutton
                id="inline-form-select-likes-count"
                class="number-selector"
                v-model="reviewCount"
                min="0"
                inline
              ></b-form-spinbutton>
              <p class="item-text" :style="{ display: 'inline-block' }">&nbsp;&nbsp;件以上</p>
            </div> -->

            <!-- イベント開催日（イベントのみ選択時に表示） -->
            <div v-if="onlyEvent">
              <p class="item-title">イベント開催日(選択)</p>
              <div class="event-held-date-calender-wrapper">
                <AppCalendar
                  :initialMonth="calendarInitialMonth"
                  :reservedDates="selectedDates"
                  @change-date-selection="$_onChangeDateSelection"
                />
              </div>
            </div>

            <b-button
              variant="dark"
              class="ml-1 mr-1 mb-2 title w-100"
              @click="$_clearConditions"
            >
              クリア
            </b-button>
          </div>
        </b-collapse>
      </div>

      <div class="optional-menu-container">
        <a
          class="item"
          v-for="item in optionalMenu"
          :key="`optional-menu-item-${item.id}`"
          @click="() => $_onClickOptionalMenu(item)"
        >
          {{ item.label }}
        </a>
      </div>
    </b-list-group>
  </b-sidebar>
</template>

<script>
import PlaceTypeSwitches from '@/components/molecules/user/AppUserPlaceTypeSwitches.vue'
import AppCalendar from '@/components/organisms/common/AppCommonCalendar'
import { SIDE_MENU, OPTIONAL_MENU } from '@/constants/admin'
import prefectures from '@/js/prefectures'
import { mapGetters } from 'vuex'

export default {
  name: 'AppPcUserAside',
  components: {
    PlaceTypeSwitches,
    AppCalendar
  },
  data() {
    return {
      isConditionOpen: false,
      sideMenu: SIDE_MENU,
      optionalMenu: OPTIONAL_MENU,
      dirty: false,
      likesCount: 0,
      reviewCount: 0,
      selectedDistance: null,
      selectedDates: [],
      distances: [
        { value: null, text: '' },
        { value: 1, text: '1km' },
        { value: 5, text: '5km' },
        { value: 10, text: '10km' },
        { value: 25, text: '25km' },
        { value: 50, text: '50km' },
        { value: 100, text: '100km' },
      ],
      selectedPrefecture: '',
    }
  },
  computed: {
    ...mapGetters('map', [ 'currentLocation' ]),
    ...mapGetters('filter', [ 'conditions' ]),
    calendarInitialMonth: function () {
      return new Date()
    },
    noTypeSelected() {
      return !this.conditions.showSpace && !this.conditions.showEvent
    },
    onlySpace() {
      return this.conditions.showSpace && !this.conditions.showEvent
    },
    onlyEvent() {
      return this.conditions.showEvent && !this.conditions.showSpace
    },
    prefectureOptions() {
      return [ { value: null, text: '全都道府県'}, ...prefectures ]
    },
  },
  watch: {
    likesCount() {
      this.$store.dispatch(
        'filter/setConditions',
        { likesCount: this.likesCount }
      )
    },
    reviewCount() {
      this.$store.dispatch(
        'filter/setConditions',
        { reviewCount: this.reviewCount }
      )
    },
    selectedDistance() {
      this.$store.dispatch(
        'filter/setConditions',
        { distance: this.selectedDistance }
      )
    },
    selectedPrefecture() {
      this.$store.dispatch(
        'filter/setConditions',
        { prefecture: this.selectedPrefecture }
      )
    },
    selectedDates() {
      this.$store.dispatch(
        'filter/setConditions',
        { heldDates: this.selectedDates }
      )
    }
  },
  mounted() {
    const {
      likesCount,
      reviewCount,
      distance,
      prefecture,
      heldDates,
    } = this.conditions
    this.likesCount = likesCount || 0
    this.reviewCount = reviewCount || 0
    this.selectedDistance = distance || null
    this.selectedDates = heldDates || []
    this.selectedPrefecture = prefecture || null
  },
  methods: {
    $_onChangeDateSelection: function (date) {
      this.selectedDates = date
    },
    $_clearConditions() {
      this.likesCount = 0
      this.reviewCount = 0
      this.selectedDistance = null
      this.selectedDates = []
      this.selectedPrefecture = null
    },
    $_onClickOptionalMenu(item) {
      if (!item) {
        return
      } else if (item.type === 'link') {
        window.open(item.url, '_blank', 'noopener,noreferrer')
      } else if (item.type === 'modal')
        this.$emit('click-modal-menu-item', item.id)
    }
  },
}
</script>

<style lang="scss" scoped>
.condition-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 16px * 2);
  margin: 0 auto;
  svg {
    margin-left: 4px;
  }
}
/* overwrite */
::v-deep(.custom-sidebar) {
  background-color: #EAEAEA;
  height: 100%;
  .list-group {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
.list-group-item {
  outline: none;
  background-color: #EAEAEA;
  border: none;
}
.custom-sidebar-logo {
  height: 56px;
}
.filter-condition-collapse {
  max-height: 75%;
  overflow-y: auto;
}
.filter-conditions {
  font-size: 14px;
  margin: 4px 16px;
  .item-title {
    font-size: 14px;
    font-weight: bold;
  }
  .item-text{
    font-size: 12px;
  }
  .number-selector {
    height: 24px;
  }
  .event-held-date-calender-wrapper {
    width: 100%;
    height: 100%;
    margin: 16px 0;
    background-color: #fff;
  }
}
::v-deep(.selected-month) {
  font-size: 14px;
}
.active_item_background_color {
  background-color: #f8f9fa;
}
.navbar-height {
  border-bottom: solid 1px lightgray;
  height: 56px;
}
.optional-menu-container {
  display: flex;
  flex-direction: column;
  margin: 20px 20px 40px 20px;
  line-height: 2;
  .item {
    cursor: pointer;
    text-decoration: none;
    color: #707070;
  }
}

/* サイドバートグル時はロゴ非表示 */
@media (max-width: 992px) {
  .posh-logo {
    visibility: hidden;
  }
}
</style>
