<template>
  <b-modal
    :id="id"
    :size="size"
    :content-class="'app-pc-user-modal-contents'"
    :header-class="'app-pc-user-modal-header'"
    centered
    scrollable
    hide-footer
  >
    <template #modal-header>
      <p class="float-left mb-0 title">{{ title }}</p>
      <IconClose class="close-icon" @click="() => $bvModal.hide(id)" />
    </template>
    <template slot>
      <slot name="contents" />
    </template>
  </b-modal>
</template>
  
  <script>
  import IconClose from '@/assets/image/icon-close.svg?component'

  export default {
    name: 'AppPcUserModal',
    components: {
      IconClose
    },
    props: {
      id: {
          type: String,
          default: 'app-pc-user-modal'
      },
      size: {
          type: String,
          default: 'md'
      },
      title: {
          type: String,
          default: ''
      },
    },
  }
  </script>
  
  <style lang="scss" scoped>
  ::v-deep(.app-pc-user-modal-header) {
    height: fit-content;
    color: #707070;
    justify-content: space-between;
    align-items: center;
    .title {
      font-size: 20px;
    }
    .close-icon {
      cursor: pointer;
      position: absolute;
      top: 20px;
      right: 20px;
    }
  }
  ::v-deep(.app-pc-user-modal-contents) {
    padding: 16px 24px 24px 32px;
    border-radius: 16px;
  }
  </style>
