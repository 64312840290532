<template>
  <div class="app-admin-list">
    <PageFrame @open-modal="$_showModal">
      <template v-slot:content>
        <div :is="contentComponent"></div>
      </template>
    </PageFrame>
    <!-- Modal -->
    <GuidelineModal />
    <PrivacyPolicyModal />
    <ManagementCompanyModal />

    <LoadingMask />
  </div>
</template>

<script>
import LoadingMask from '@/components/molecules/common/AppFullscreenLoadingMask'
import PageFrame from '@/components/organisms/user/pc/AppPcUserPageFrame'
import GuidelineModal from '@/components/organisms/user/pc/AppPcUserGuidelineModal'
import PrivacyPolicyModal from '@/components/organisms/user/pc/AppPcUserPrivacyPolicyModal'
import ManagementCompanyModal from '@/components/organisms/user/pc/AppPcUserManagementCompanyModal'
import AppPcUserMap from '@/components/organisms/user/pc/AppPcUserMap'

export default {
  name: 'AppPcUserPage.',
  components: {
    LoadingMask,
    PageFrame,
    AppPcUserMap,
    GuidelineModal,
    PrivacyPolicyModal,
    ManagementCompanyModal
  },
  props: {
    'contentComponent': {
      type: String,
      default: 'AppPcUserMap'
    },
  },
  methods: {
    $_showModal(modalId) {
      this.$bvModal.show(modalId)
    } 
  }
}
</script>

<style lang="scss"></style>