<template>
  <b-navbar class="app-pc-user-header" type="dark" fixed="top">
    <b-button
      class="mr-3 border"
      id="menubtn"
      variant="light"
      v-b-toggle.sidebar-backdrop
    >
      <b-icon icon="list" aria-hidden="true"></b-icon>
    </b-button>

    <b-navbar-brand id="brand" class="mr-auto">
      <!-- Safari でSVGのグラデーションが効かないためpngで対応 -->
      <img :src="require('@/assets/image/logo/logo.png')" />
    </b-navbar-brand>
  </b-navbar>
</template>

<script>
export default {
  name: 'AppPcUserHeader',
}
</script>

<style lang="scss" scoped>
.app-pc-user-header {
  border-bottom: solid 1px lightgray;
  height: 56px;
}
</style>
