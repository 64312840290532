<template>
  <div v-if="placeDetail" class="app-user-space-detail">
    <!-- Header -->
    <div class="header-wrapper">
      <!-- PCユーザーのお気に入り操作は、ログイン機能実装後にしたい -->
      <!-- <div v-if="isSideDrawerOpen" class="like-button-wrapper">
        <LikeButton
          :liked="isLiked" 
          :isProcessing="isProcessing"
          :width="'46px'"
          :height="'46px'"
          @like-button-clicked="$_switchDisplayStyle"
        />
      </div> -->
      <div class="space-detail-chip-wrapper">
        <Chip
          :title="'スペース'"
          :textColor="'#fff'"
          :backgroundColor="'#12B6D4'"
        />
      </div>
      <div class="title-detail-wrapper">
        <div class="space-name">{{ spaceName }}</div>
        <div class="like-count-wrapper">
          <b-icon
            v-bind:icon="'heart-fill'"
            aria-hidden="true" dark
          />
          <span class="like-count-number">
            {{ likesCount }}
          </span>
        </div>
      </div>
    </div>

    <!-- Body -->
    <div class="body-wrapper">
      <b-card no-body>
        <!-- StreetView -->
        <div v-html="embeddedStreetView" />

        <!-- Twitter link button -->
        <div
          v-if="twitterKeywordUrl"
          class="twitter-link-button-container"
        >
          <TwitterLinkButton
            class="twitter-link-button"
            @click="$_openLink(twitterKeywordUrl)"
          />
          <p class="twitter-keywords mt-1">検索キーワード：</p>
          <p class="twitter-keywords">{{ twitterKeywords }}</p>
        </div>

        <!-- Map -->
        <div class="access-map-container">
          <div class="item-title">アクセス</div>
          <div class="access-map">
            <MapContents
              v-if="coordinates"
              :showUiLayer="false"
              :places="[placeDetail]"
              :mapCenter="coordinates"
              :mapZoom="16"
              :mapOptions="mapOptions"
              :panTo="panTo"
              @map-loadend="({ map }) => {
                map.setZoom(18)
                map.panTo(coordinates)
              }"
            />
          </div>
          <p class="open-streetview" @click="$_onClickStreetView">
            Googleマップを別ウィンドウで開く
          </p>
          <p class="pin-position-remarks">
            ※ピンの位置は住所情報をもとに取得されるため、正確な位置からずれて表示される場合や、実際の出店場所とは異なる場合がありますのでご注意ください。
          </p>
        </div>

        <!-- Address -->
        <div class="address-container">
          <div class="item-title">
            住所
          </div>
          <div class="address-detail">
            <p class="zip-code">〒{{ address.zip }}</p>
            <p class="address-string">
              {{ address.prefecture }}{{ address.area }} {{ address.address }}
            </p>
          </div>
        </div>

        <!-- Apply Url -->
        <div class="apply-url-container">
          <div class="item-title">募集ページ</div>
          <div class="apply-url-detail">
            <a
              v-if="applyUrl"
              class="apply-urls"
              target="_blank"
              rel="noopener noreferrer"
              :href="applyUrl"
            >
              {{ applyUrl }}
            </a>
            <div v-else>
              <p class="no-apply-url-detail">
                情報募集中です。<br />
                スペースの出店募集情報のご提供は
                <a
                  class="no-apply-url-link"
                  target="_blank"
                  rel="noopener"
                  :href="'https://docs.google.com/forms/d/e/1FAIpQLSepG3MOTJN6hKO3EVvOL8q-_xPG5PcEUqRUDZOrBw7ZbyYrAQ/viewform'"
                >
                  お問い合わせ
                </a>
                までお寄せください。
              </p>
              <p class="no-apply-url-sub-detail">
                ※お寄せいただいた情報については、掲載をお約束するものではございませんので、予めご了承ください。
              </p>
            </div>
          </div>
        </div>

        <!-- Remarks -->
        <div v-if="remarks" class="ramarks-container">
          <div class="item-title">
            備考
          </div>
          <div>
            <CommonTextarea
              :prefix="'space-remarks'"
              :shown="shown"
              :readonly="true"
              :userStyle="{ margin: '8px 0', fontSize: '14px' }"
              :remarks="remarks"
            />
          </div>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import CommonTextarea from '@/components/atoms/common/AppTextarea'
// import LikeButton from '@/components/molecules/user/AppUserLikeButton'
import MapContents from '@/components/molecules/user/AppUserMapContents'
import Chip from '@/components/molecules/user/AppUserChip.vue'
import TwitterLinkButton from '@/assets/image/button_twitter_link.svg?component'
import { getSpace } from '@/helper/firestore/space'
import { openTwitterLink, showBottomInfoToast } from '@/helper/common'
import { updateLikes } from '@/helper/user'
import { mapGetters } from 'vuex'

export default {
  name: 'AppUserPcSpaceDetail',
  components: {
    CommonTextarea,
    MapContents,
    // LikeButton,
    Chip,
    TwitterLinkButton,
  },
  props: {
    isSideDrawerOpen: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      placeDetail: null,
      shown: false,
      isProcessing: false,
      panTo: null,
      mapOptions: {
        disableDefaultUI: false,
        mapTypeControl: false,
        fullscreenControl: false,
        clickableIcons: false,
        keyboardShortcuts: false,
        zoomControl: true,
        streetViewControl: false,
        gestureHandling: 'cooperative'
      }
    }
  },
  watch: {
    async selectedPlace() {
      await this.$_setSpace()
      // 新しいプレイス位置へ地図をパン
      this.panTo = this.coordinates
    }
  },
  mounted: async function() {
    await this.$_setSpace()
  },
  computed: {
    ...mapGetters(
      'map',
      [ 'mapCenter', 'mapZoom' ]
    ),
    selectedPlace() { // Chunkデータ
      return this.$store.getters['place/selectedPlace']
    },
    selectedPlaceId() {
      return this.$store.getters['place/selectedPlaceId']
    },
    showAds: function () {
      return !location.href.includes('https://poshspace.jp')
    },
    spaceName: function () {
      return this.placeDetail.spaceName
    },
    likesCount: function () {
      return (this.placeDetail && this.placeDetail.likes)
        ? Number(this.placeDetail.likes)
        : 0
    },
    bookmarkedPlaceIds: function () {
      return this.$store.getters.bookmarkedPlaceIds
    },
    isLiked: function () {
      return this.bookmarkedPlaceIds.includes(this.selectedPlaceId)
    },
    title: function () {
      return this.placeDetail.spaceName
        ? `${this.placeDetail.facilityName}/${this.placeDetail.spaceName}`
        : this.placeDetail.facilityName
    },
    address: function() {
      return {
        zip: this.placeDetail.zip,
        prefecture: this.placeDetail.prefecture,
        area: this.placeDetail.area,
        address: this.placeDetail.address
      }
    },
    coordinates: function () {
      return this.placeDetail && this.placeDetail.coordinates
    },
    embeddedStreetView: function () {
      return (this.placeDetail && this.placeDetail.embeddedStreetView)
        ? this.placeDetail.embeddedStreetView
          .replace('width="600" height="450"', 'width="100%" height="200"')
        : ''
    },
    twitterKeywordUrl: function () {
      return (this.placeDetail && this.placeDetail.twitterKeywordUrl)
        ? this.placeDetail.twitterKeywordUrl
        : ''
    },
    twitterKeywords: function () {
      return (this.placeDetail && this.placeDetail.twitterKeywords)
        ? this.placeDetail.twitterKeywords.replace(/"/g, '').replace(/,/g, ' ')
        // ? `検索キーワード：${this.placeDetail.twitterKeywords.replace(/"/g, '').replace(/,/g, ' ')}`
        : ''
    },
    applyUrl: function () {
      return (this.placeDetail && this.placeDetail.applyUrl)
        ? this.placeDetail.applyUrl
        : ''
    },
    remarks: function () {
      return (this.placeDetail && this.placeDetail.remarks)
        ? this.placeDetail.remarks
        : ''
    },
    remarksRows: function () {
      return this.remarks
        ? this.remarks.split(/\n/g).length
        : 1
    },
  },
  methods: {
    /**
     * お気に入りボタンクリック処理
     */
    $_switchDisplayStyle: async function () {
      try {
        this.isProcessing = true

        // リモート、ストアのデータを更新
        await updateLikes(this.selectedPlace)
        // リモートデータ再取得
        this.placeDetail = await getSpace(this.selectedPlaceId)

        // 現状PC版に個人のお気に入りを見る機能はないので表示不要
        // showBottomInfoToast('info', this.toastText, 1500)
      } catch (error) {
        showBottomInfoToast('error', `いいね!更新処理に失敗しました ${error}`, 150000)
      } finally {
        this.isProcessing = false
      }
    },
    /**
     * スペース情報を取得して設定
     */
    async $_setSpace() {
      this.$_showLoadingMask()
      try {
        this.placeDetail = await getSpace(this.selectedPlaceId)
        //FIXME: 待たないとtextarea の scrollHeight がとれない
        await this.$nextTick()
        this.shown = true
      } catch (error) {
        alert(error)
      } finally {
        this.$_hideLoadingMask()
      }
    },
    /**
     * Twitterリンクを別タブで開く
     * @param {String} url 
     */
    $_openLink: function (url) {
      openTwitterLink(url)
    },
    /**
     * ストリートビューへのリンクへ遷移
     */
    $_onClickStreetView: function () {
      const coords = `
        ${this.placeDetail.coordinates.lat},
        ${this.placeDetail.coordinates.lng}
      `
      window.open(
        `https://www.google.com/maps/search/?api=1&query=${coords}`,
        '_blank',
        'noreferrer'
      )
    },
    $_showLoadingMask() {
      this.$store.dispatch(
        'loadingMask/setPartialId',
        'pc-side-slide-contents'
      )
    },
    $_hideLoadingMask() {
      this.$store.dispatch('loadingMask/setPartialId', null)
    },
  }
}
</script>

<style lang="scss" scoped>
.app-user-space-detail {
  width: 100%;
  height: 100%;
  .header-wrapper {
    height: 96px;
    position: relative;
    box-shadow: none;
    .like-button-wrapper { 
      position: absolute;
      top: 0px;
      right: 20px;
      z-index: 9999;
      cursor: pointer;
    }
    .space-detail-chip-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #707070;
      position: sticky;
      background-color: #fff;
      top: 0;
      z-index: 999;
    }
    .title-detail-wrapper {
      position: sticky;
      top: 20px;
      z-index: 999;
      background-color: #fff;
      .space-name {
        font-weight: bold;
        color: #707070;
        padding: 8px 0;
      }
      .like-count-wrapper {
        color: #12B6D4;
        display: flex;
        align-items: center;
        font-weight: bold;
        margin-bottom: 8px;
        .like-count-number {
          margin: 0 0 0 8px;
        }
      }
    }
  }
  .body-wrapper {
    height: calc(100% - 96px);
    overflow-y: auto;
    /* overwrite */
    .card {
      border: none;
    }
    .item-title {
      font-weight: bold;
      margin: 2rem 0 1.25rem 0;
    }
    .twitter-link-button-container {
      color: #707070;  
      margin: 20px 0 16px 0;
      .twitter-link-button {
        width: 100%;
      }
      .twitter-keywords {
        margin-bottom: 0;
        font-size: 12px;
      }
    }
    .access-map-container {
      color: #707070;  
      margin-bottom: 16px;

      .access-map {
        height: 260px;
      }
      .open-streetview {
        text-decoration: underline;
        font-size: 12px;
        line-height: 36px;
      }
      .pin-position-remarks {
        color: #707070;
        font-size: 12px;
        margin-bottom: 0;
      }
    }
    .ramarks-container {
      color: #707070;
      margin-bottom: 16px;
    }
    .address-container {
      color: #707070;  
      .zip-code {
        font-size: 13px;
      }
      .address-string {
        font-size: 16px;
      }
    }
    .apply-url-container {
      color: #707070;  
      margin-bottom: 16px;
      .apply-url-detail {
        .apply-urls {
          color: #707070 !important;  
          font-size: 16px;
          text-decoration: underline;
        }
        .no-apply-url-detail {
          font-size: 14px;
        }
        .no-apply-url-link {
          color: #707070;
          text-decoration: underline;
        }
        .no-apply-url-sub-detail {
          font-size: 12px;
        }
      }
    }
  }
}
</style>