<template>
  <div v-if="show" class="loading-mask">
    <div class="loader loader-1" />
  </div>
</template>

<script>
export default {
  name: 'AppPartialLoadingMask',
  props: {
    partialId: {
      type: String,
      default: null
    }
  },
  computed: {
    show() {
      return this.partialId === this.$store.getters['loadingMask/partialId']
    },
  }
}
</script>

<style lang="scss" scoped>
body {
  max-width: 1000px;
  margin: 100px auto 0;
  padding-left: 6.25%;
}
.loading-mask {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  text-align: center;
  color: #0cf;
  background-color: #f5f5f5b0;
  z-index: 9999;
  .loader {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 50px;
    border: 2px solid #0cf;
    border-radius: 50%;
    border-top-width: 0;
    animation: spin 0.75s infinite linear;
  }
  .info-wrapper {
    position: absolute;
    top: 54%;
  }
  .percent {
    width: 300px;
    font-weight: bold;
    font-size: 24px;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>