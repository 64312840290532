<template>
  <AppModal :id="modal.id" :title="modal.label" :size="'xl'">
    <template v-slot:contents>
      <div class="pc-user-guideline-item">
        Poshspace（以下 「本サービス」といいます。）のご利用にあたり、このPoshspace利用規約 （以下 「本規約」といいます。）のすべての条項をお読みいただき、同意していただく必要がございます。
      </div>

      <div
        v-for="(item, index) in userGuideline"
        :key="`${item.page}-pc-user-guideline-${index}`"
      >
        <div
          v-for="(content, index) in item.contents"
          :key="`${item.page}-pc-user-guideline-item-wrapper-${index}`"
        >
          <div class="pc-user-guideline-item-title-sub">{{ content.title }}</div>
          <div class="pc-user-guideline-item">
            <p
              style="marginBottom: 0;"
              v-for="(line, index) in content.text"
              :key="`${item.page}-pc-user-guideline-item-${index}`"
              class="line"
            >
              {{ line || '&nbsp;' }}
            </p>
          </div>
        </div>
      </div>

      <div>
        <div class="pc-user-guideline-item mt-5 mb-5">
          {{ fixDateText }}
        </div>
        <div class="pc-user-guideline-item mt-5">
          運営者<br />
          株式会社ティーピーズ
        </div>
      </div>
    </template>
  </AppModal>
</template>
  
  <script>
  import AppModal from '@/components/molecules/user/AppPcUserModal'
  import { USER_GUIDELINE,USER_GUIDELINE_FIXED_DATE  } from '@/constants/user'
  import { OPTIONAL_MENU } from '@/constants/admin'
  
  export default {
    name: 'AppPcUserGuidelineModal',
    components: {
      AppModal
    },
    data() {
      return {
        modal: OPTIONAL_MENU.find(item => item.id === 'user-guideline'),
        userGuideline: USER_GUIDELINE,
        fixDateText: USER_GUIDELINE_FIXED_DATE,
      }
    },
  }
  </script>
  
  <style lang="scss" scoped>
  .pc-user-guideline-item {
    font-size: 14px;
    color: #707070;
    .line { line-height: 1.5rem; }
  }
  .pc-user-guideline-item-title-sub {
    font-size: 16px;
    font-weight: bold;
    margin: 42px 0 24px 0;
    color: #707070;
  }
  </style>
  