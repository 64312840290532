export const SIDE_MENU = [
  {
    text: "スペース一覧",
    key: "space_list",
    to: "/admin/space_list",
    activeTargets: [
      "space_list",
      "space_detail",
      "space_create",
      "space_download_csv",
      "space_upload_csv"  
    ]
  },
  {
    text: "イベント一覧",
    key: "event_list",
    to: "/admin/event_list",
    activeTargets: [
      "event_list",
      "event_detail",
      "event_create",
      "event_download_csv",
      "event_upload_csv"
    ]
  },
  {
    text: "クチコミ管理",
    key: "review_list",
    to: "/admin/review_list",
    activeTargets: [
      "review_list",
      "objection_handle",
      "review_handle",
    ]
  },
  {
    text: "お知らせ管理",
    key: "news",
    to: "/admin/news",
    activeTargets: [
      'news',
      'news_edit',
      'news_create',
      'news_detail',
      'news_saved',
    ]
  },
  {
    text: "マイページ",
    key: "myPage",
    to: "/admin/my_page",
    activeTargets: [
      'my_page',
      'my_page_edit_name_saved',
      'my_page_edit_email_saved',
      'my_page_edit_email_saved'
    ]
  },
]

export const OPTIONAL_MENU = [
  {
    id: 'posh-info',
    label: '運営会社',
    type: 'modal'
  },
  {
    id: 'contact',
    label: 'お問い合わせ',
    type: 'link',
    url: 'https://docs.google.com/forms/d/e/1FAIpQLSepG3MOTJN6hKO3EVvOL8q-_xPG5PcEUqRUDZOrBw7ZbyYrAQ/viewform'
  },
  {
    id: 'user-guideline',
    label: '利用規約',
    type: 'modal'
  },
  {
    id: 'privacy-policy',
    label: 'プライバシーポリシー',
    type: 'modal'
  },
]