<template>
  <AppModal :id="modal.id" :title="modal.label" :size="'md'">
    <template v-slot:contents>
      <div
        v-for="(item, index) in managementCompany"
        :key="`posh-info-${index}`"
      >
        <div
          class="posh-info-item-title-sub"
          :style="{marginTop: index === 0 ? '0px' : '32px'}"
        >
          {{ item.title }}
        </div>
        <div class="posh-info-item">
          <p
            style="marginBottom: 0;"
            v-for="(line, index) in item.text"
            :key="`posh-info-item-${index}`"
            class="line"
          >
            {{ line || '&nbsp;' }}
          </p>
        </div>
      </div>
      <div
        class="posh-info-item-title-sub"
        :style="{marginTop: '32px'}"
      >
        連絡先
      </div>
      <div class="posh-info-item">
        <a
          :style="{  color: '#707070', textDecoration: 'none' }"
          :href="`mailto:${adminEmail}`"
        >
          {{ adminEmail }}
        </a>
      </div>
    </template>
  </AppModal>
</template>
  
<script>
  import AppModal from '@/components/molecules/user/AppPcUserModal'
  import { MANAGEMENT_COMPANY } from '@/constants/user'
  import { OPTIONAL_MENU } from '@/constants/admin'
  import { ADMIN_EMAIL } from '@/constants/common'
  
  export default {
    name: 'AppPcUserManagementCompanyModal',
    components: {
      AppModal
    },
    data() {
      return {
        modal: OPTIONAL_MENU.find(item => item.id === 'posh-info'),
        managementCompany: MANAGEMENT_COMPANY,
        adminEmail: ADMIN_EMAIL
      }
    },
  }
</script>
  
<style lang="scss" scoped>
  .posh-info-item {
    font-size: 16px;
    color: #707070;
    .line { line-height: 1.5rem; }
  }
  .posh-info-item-title-sub {
    font-size: 13px;
    color: #A5A5A5;
  }
</style>
  